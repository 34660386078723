import { default as customerwIiaPWch52Meta } from "F:/webroot/abuy.ilovearomagrace.com/pages/customer.vue?macro=true";
import { default as homeZia1dzPGXwMeta } from "F:/webroot/abuy.ilovearomagrace.com/pages/home.vue?macro=true";
import { default as indexfwIXK1Cn62Meta } from "F:/webroot/abuy.ilovearomagrace.com/pages/index.vue?macro=true";
import { default as index1U8US50wzzEMeta } from "F:/webroot/abuy.ilovearomagrace.com/pages/index1.vue?macro=true";
import { default as login0R36oorIQuMeta } from "F:/webroot/abuy.ilovearomagrace.com/pages/login.vue?macro=true";
import { default as _91id_93QtnbYeZGbNMeta } from "F:/webroot/abuy.ilovearomagrace.com/pages/order/[id].vue?macro=true";
import { default as searchorderDt5fgYj4DnMeta } from "F:/webroot/abuy.ilovearomagrace.com/pages/searchorder.vue?macro=true";
export default [
  {
    name: customerwIiaPWch52Meta?.name ?? "customer",
    path: customerwIiaPWch52Meta?.path ?? "/customer",
    meta: customerwIiaPWch52Meta || {},
    alias: customerwIiaPWch52Meta?.alias || [],
    redirect: customerwIiaPWch52Meta?.redirect || undefined,
    component: () => import("F:/webroot/abuy.ilovearomagrace.com/pages/customer.vue").then(m => m.default || m)
  },
  {
    name: homeZia1dzPGXwMeta?.name ?? "home",
    path: homeZia1dzPGXwMeta?.path ?? "/home",
    meta: homeZia1dzPGXwMeta || {},
    alias: homeZia1dzPGXwMeta?.alias || [],
    redirect: homeZia1dzPGXwMeta?.redirect || undefined,
    component: () => import("F:/webroot/abuy.ilovearomagrace.com/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexfwIXK1Cn62Meta?.name ?? "index",
    path: indexfwIXK1Cn62Meta?.path ?? "/",
    meta: indexfwIXK1Cn62Meta || {},
    alias: indexfwIXK1Cn62Meta?.alias || [],
    redirect: indexfwIXK1Cn62Meta?.redirect || undefined,
    component: () => import("F:/webroot/abuy.ilovearomagrace.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index1U8US50wzzEMeta?.name ?? "index1",
    path: index1U8US50wzzEMeta?.path ?? "/index1",
    meta: index1U8US50wzzEMeta || {},
    alias: index1U8US50wzzEMeta?.alias || [],
    redirect: index1U8US50wzzEMeta?.redirect || undefined,
    component: () => import("F:/webroot/abuy.ilovearomagrace.com/pages/index1.vue").then(m => m.default || m)
  },
  {
    name: login0R36oorIQuMeta?.name ?? "login",
    path: login0R36oorIQuMeta?.path ?? "/login",
    meta: login0R36oorIQuMeta || {},
    alias: login0R36oorIQuMeta?.alias || [],
    redirect: login0R36oorIQuMeta?.redirect || undefined,
    component: () => import("F:/webroot/abuy.ilovearomagrace.com/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91id_93QtnbYeZGbNMeta?.name ?? "order-id",
    path: _91id_93QtnbYeZGbNMeta?.path ?? "/order/:id()",
    meta: _91id_93QtnbYeZGbNMeta || {},
    alias: _91id_93QtnbYeZGbNMeta?.alias || [],
    redirect: _91id_93QtnbYeZGbNMeta?.redirect || undefined,
    component: () => import("F:/webroot/abuy.ilovearomagrace.com/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: searchorderDt5fgYj4DnMeta?.name ?? "searchorder",
    path: searchorderDt5fgYj4DnMeta?.path ?? "/searchorder",
    meta: searchorderDt5fgYj4DnMeta || {},
    alias: searchorderDt5fgYj4DnMeta?.alias || [],
    redirect: searchorderDt5fgYj4DnMeta?.redirect || undefined,
    component: () => import("F:/webroot/abuy.ilovearomagrace.com/pages/searchorder.vue").then(m => m.default || m)
  }
]