export default defineNuxtRouteMiddleware((to, from) => {
  if (to.path === '/login') {
    return; // 如果已經在登錄頁面，則不執行重定向
  }

  if (process.server) {
    const event = useRequestEvent();
    const { session } = event.context;

    if (!session || !session.userId) {
      // 如果沒有有效的 session，重定向到登錄頁面並附帶原始路徑
      return navigateTo(`/login?redirect=${encodeURIComponent(to.fullPath)}`, { redirectCode: 301 });
    }
  }
});
