<template>
  <div class="leading-normal tracking-normal border-none" id="main-body">
    <div class="flex flex-wrap">
      <div class="w-full bg-gray-100 pl-0 lg:pl-64 min-h-screen" id="main-content">
        <BaseNavbar />
        <BaseSidebar /> 
        <div class="p-6 bg-gray-100 mb-20">
          <slot />
        </div>
        <BaseFooter />
      </div>
    </div>
  </div>
</template>