<template>
  <div class="container">
    <el-form @submit.prevent="login" class="login-form" label-position="top">
      <el-form-item label="Username">
        <el-input v-model="username" placeholder="Enter your username" />
      </el-form-item>
      <el-form-item label="Password">
        <el-input v-model="password" type="password" placeholder="Enter your password" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" native-type="submit">Login</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import { ElMessage } from 'element-plus';

// 使用 definePageMeta 设置页面元数据
definePageMeta({
  layout: 'none' // 指定不使用任何布局
})

const username = ref('');
const password = ref('');
const route = useRoute();
const router = useRouter();

const login = async () => {
  try {
    await axios.post('/api/login', {
      username: username.value,
      password: password.value,
    });
    ElMessage({
      message: '登录成功',
      type: 'success',
    });

    // 登录成功后，检查 URL 中是否有 redirect 参数
    const redirect = route.query.redirect;
    if (redirect) {
      router.push(decodeURIComponent(redirect));
    } else {
      // 如果没有 redirect 参数，导航到默认页面
      router.push('/customer');
    }
  } catch (error) {
    ElMessage({
      message: '登录失败: ' + error.message,
      type: 'error',
    });
    console.error('Login failed:', error);
  }
};
</script>

  
<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-form {
  width: 250px;
  /* 更小的宽度 */
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  /* 添加一些阴影效果 */
  padding: 20px;
  border-radius: 10px;
  /* 圆角边框 */
}
</style>